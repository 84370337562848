/* Unlock Price Slider */

.unlock-price-slider {
  height: 20px;
  display: flex;
  align-items: center;
}

.unlock-price-slider-marks {
  height: 2px;
  width: 2px;
}

.unlock-price-slider-thumb {
  background-color: #4b4b4b;
  border-radius: 50%;
  width: 11px;
  height: 11px;
  z-index: 0 !important;
}

.unlock-price-slider-track {
  background-color: #979797;
  height: 3px;
}
